// noinspection JSUnusedGlobalSymbols,JSUnresolvedVariable

import React, { useMemo, useState } from 'react'
import { graphql } from 'gatsby'
import Zoom from 'react-reveal/Zoom'

import { Layout, SectionContainer, SectionText, SectionTitle } from '../components/_layout'
import { Grid, Typography } from '@material-ui/core'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Evento } from '../components/Experiencias'
import { Blogposts, Categorias } from '../components/Blog'


const Blog = ( { data } ) => {

  const allBlogposts = data.allBlogPostsData.edges.map( ( { node } ) => ({
    id: node.id,
    titulo: node.titulo,
    slug: node.slug,
    categoria: node.categoria,
    fecha: node.fechaDeCreacion,
    imagen: node.imagenPrincipal
  }) )

  const categorias = data.categoriasData.edges.map( ( { node } ) => ({
        id: node.id,
        nombreCategoria: node.categoria,
        posts: node.blogpost
               ? node.blogpost.map(
                ( post ) => ({
                  id: post.id,
                  titulo: post.titulo,
                  slug: post.slug,
                  fecha: post.fechaDeCreacion,
                  imagen: post.imagenPrincipal
                })
            )
               : []
      })
  )

  categorias.unshift( {
    id: 0,
    nombreCategoria: 'Todos',
    posts: allBlogposts
  } )

  const [ currentCategoriaID, setCurrentCategoriaID ] = useState( categorias[0].id )

  const handleCategoriaSelection = ( idCategoria ) => {
    const currentCategoria = categorias.find( e => e.id === idCategoria )
    setCurrentCategoriaID( currentCategoria.id )
  }

  const currentPost = useMemo(() => {
    return categorias.find( e => e.id === currentCategoriaID )
  }, [currentCategoriaID])

  return (
      <Layout
          withNav
          seoTitle="Blog"
          seoDescription="Conoce nuestro blog."
      >
        <SectionContainer sectionID="blog">
          <Grid style={ { height: 64, width: '100%' } }/>
          <SectionTitle title={ 'Blog' }/>
          <Grid container direction={ 'column' }>
            <Grid item>
              <Categorias
                  onCategoriaChange={ handleCategoriaSelection }
                  { ...{ categorias, currentCategoriaID } } />
            </Grid>

            <Grid item xs>
              <Blogposts blogposts={ currentPost?.posts || [] }/>
            </Grid>
          </Grid>
        </SectionContainer>
      </Layout>
  )
}

export default Blog

export const BlogQuery = graphql`
    query BlogQuery {
        categoriasData: allContentfulCategoriaBlog {
            edges {
                node {
                    id
                    categoria
                    blogpost {
                        id
                        titulo
                        slug
                        fechaDeCreacion(formatString: "DD.MM.YY")
                        imagenPrincipal {
                            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                        }
                    }
                }
            }
        }
        allBlogPostsData: allContentfulBlogPost {
            edges {
                node {
                    id
                    titulo
                    slug
                    categoria {
                        categoria
                    }
                    fechaDeCreacion(formatString: "DD.MM.YY")
                    imagenPrincipal {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                }
            }
        }
    }
`
