import { Grid } from '@material-ui/core'
import React from 'react'
import Post from './Post'

import useStyles from './styles'


const Blogposts = ({ blogposts }) => {
  const styles = useStyles()
  return (
      <Grid
          container
          className={styles.blogpostsRoot}
      >
        {
          blogposts.map((post, index) => <Post key={index} {...{ post }} />)
        }
      </Grid>
  )
}

export default Blogposts
