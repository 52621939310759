// noinspection JSUnresolvedVariable

import React from 'react'

import useStyles from './styles'
import { navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Grid, Typography } from '@material-ui/core'
import Zoom from 'react-reveal/Zoom'


const Experiencia = ( { experiencia } ) => {
  const styles = useStyles()

  return (
      <Zoom>
        <Grid item className={ styles.experienciaRoot }
              onClick={ () => navigate( `/experiencias/${ experiencia.slug }` ) }>
          <GatsbyImage
              alt={ experiencia.titulo }
              image={ experiencia.imagen.gatsbyImageData }
              className={ styles.experienciaImage }/>

          <Grid item className={ styles.fecha }>
            <Typography component="span">{ experiencia.fecha }</Typography>
          </Grid>

          <Grid item>
            <Typography className={ styles.titulo }>{ experiencia.titulo }</Typography>
          </Grid>

          <Grid item>
            <Typography>{ experiencia.resumen }</Typography>
          </Grid>

        </Grid>
      </Zoom>
  )
}

export default Experiencia


