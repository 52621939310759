import { makeStyles } from '@material-ui/core'


export default makeStyles( ( theme ) => ({
  categoriasRoot: {
    [theme.breakpoints.up( 'md' )]: {
      padding: theme.spacing( 2, 0 )
    }
  },

  categoriaRoot: {
    margin: theme.spacing( 0.5 ),
    padding: theme.spacing( 0.5 ),
    color: theme.palette.text.lightText,
    backgroundColor: theme.palette.extras.charcoal,
    borderRadius: theme.roundnessSmall,
    cursor: 'pointer',
    transition: theme.palette.extras.transitions.default,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.primary
    },

    '& span': {
      fontSize: theme.typography.body2.fontSize
    }
  },

  categoriaRootSelected: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary
  }
}) )