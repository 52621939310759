import React from 'react'
import { Grid, Typography } from '@material-ui/core'

import useStyles from './styles'


const Categoria = ( { categoria, onCategoriaChange, currentCategoriaID } ) => {
  const styles = useStyles()

  const rootClassname = currentCategoriaID === categoria.id
                        ? `${ styles.categoriaRoot } ${ styles.categoriaRootSelected }`
                        : styles.categoriaRoot

  return (
      <Grid item className={ rootClassname } onClick={ () => onCategoriaChange( categoria.id ) }>
        <Typography
            component={ 'span' }>{ categoria.nombreCategoria } ({ categoria.posts.length })</Typography>
      </Grid>
  )
}

export default Categoria
