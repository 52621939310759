import React from 'react'
import { Grid } from '@material-ui/core'

import useStyles from './styles'
import Categoria from './Categoria'


const Categorias = ( { categorias, onCategoriaChange, currentCategoriaID } ) => {
  const styles = useStyles()
  return (
      <Grid container className={ styles.categoriasRoot }>
        {
          categorias.map( ( categoria ) => <Categoria { ...{
            categoria,
            onCategoriaChange,
            currentCategoriaID
          } }  /> )
        }
      </Grid>
  )
}

export default Categorias
