import { makeStyles } from '@material-ui/core'


export default makeStyles( ( theme ) => ({
  blogpostsRoot: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up( 'md' )]: {

    }
  },

  blogpostRoot: {
    [theme.breakpoints.up( 'md' )]: {
      cursor: 'pointer',
      margin: theme.spacing(2)
    }
  },

  postImage: {
    borderRadius: theme.roundnessSmall,
    width: '100%',
    background: 'white',
    [theme.breakpoints.up( 'sm' )]: {
      height: 300,
    }
  },

  titulo: {
    fontWeight: theme.typography.fontWeightBold,
    margin: theme.spacing( 1, 0 )
  },

  fecha: {
    '& span': {
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.text.disabled
    }
  }
}) )