import React from 'react'
import { Grid, Typography } from '@material-ui/core'

import useStyles from './styles'
import { navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Zoom from 'react-reveal/Zoom'


const Post = ( { post } ) => {
  const styles = useStyles()
  return (
      <Zoom>
        <Grid item className={ styles.blogpostRoot } onClick={ () => navigate( `/blog/${ post.slug }` ) }>
          <GatsbyImage
              alt={ post.titulo }
              image={ post.imagen.gatsbyImageData }
              className={ styles.postImage }/>

          <Grid container item className={ styles.fecha }>
            <Grid item xs><Typography component="span">{ post.fecha }</Typography></Grid>
            {
              post.categoria && <Grid item xs style={ { textAlign: 'right' } }><Typography
                  component="span">#{ post.categoria.categoria }</Typography></Grid>
            }

          </Grid>

          <Grid item>
            <Typography className={ styles.titulo }>{ post.titulo }</Typography>
          </Grid>

          <Grid item>
            <Typography>{ post.resumen }</Typography>
          </Grid>

        </Grid>
      </Zoom>
  )
}

export default Post
