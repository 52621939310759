import { makeStyles } from '@material-ui/core'


export default makeStyles( ( theme ) => ({
  experienciaRoot: {
    margin: theme.spacing(4, 0),
    cursor: 'pointer',
    [theme.breakpoints.up( 'sm' )]: {
      margin: theme.spacing( 2 )
    }
  },

  experienciaImage: {
    borderRadius: theme.roundnessSmall,
    width: '100%',
    background: 'white',
    [theme.breakpoints.up( 'sm' )]: {
      height: 300,
    }
  },

  titulo: {
    fontWeight: theme.typography.fontWeightBold,
    margin: theme.spacing( 1, 0 )
  },

  fecha: {
    '& span': {
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.text.disabled
    }
  }
}) )